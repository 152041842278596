var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items && _vm.items.length)?_c('ul',{staticClass:"c-area-references",style:({
		'--more': _vm.items.length - _vm.showMax,
	})},[_vm._l((_vm.showMax >= 0
			? _vm.items.slice(0, _vm.showMax)
			: _vm.items),function(item,index){return _c('li',{key:item.key,staticClass:"\n\t\t\tc-area-references__item\n\t\t\tinline-flex\n\t\t\titems-center\n\t\t\tpx-4xs/h\n\t\t\tpy-7\n\t\t\tmin-h-32\n\t\t\tborder\n\t\t\trounded-25\n\t\t\ttext-body-xs\n\t\t\tprint:hidden\n\t\t",class:{
			'border-theme border-opacity-20 text-theme': !_vm.invert,
			'border-white border-opacity-30 text-white': _vm.invert,
			'c-area-references__item--add-dots':
				_vm.showMax >= 0 &&
				_vm.items.length > _vm.showMax &&
				index === _vm.showMax - 1,
		}},[(item.iconUrl)?_c('AreaIcon',{staticClass:"w-18 h-18 mr-8",attrs:{"invert":_vm.invert,"src":item.iconUrl}}):_vm._e(),_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(item.name)}})],1)}),_vm._v(" "),_c('span',{staticClass:"hidden print:inline-block text-body-xs",domProps:{"textContent":_vm._s(_vm.printText)}}),_vm._v(" "),_vm._t("default")],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }