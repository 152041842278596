<template>
	<img
		class="c-area-icon"
		:src="computedSrc"
		alt=""
		width="100"
		height="100"
	/>
</template>

<script>
export default {
	name: 'AreaIcon',

	inject: {
		theme: {
			default: () => ({ color: 'red' }),
		},
	},

	props: {
		src: {
			type: String,
			default: '',
		},

		invert: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		computedSrc() {
			return [
				this.src + '?color=' + this.theme.color,
				this.invert ? '&invert=true' : '',
			]
				.filter(Boolean)
				.join('');
		},
	},
};
</script>

<style lang="postcss">
.c-area-icon {
	aspect-ratio: 1;
}
</style>
