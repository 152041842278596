<template>
	<ul
		v-if="items && items.length"
		class="c-area-references"
		:style="{
			'--more': items.length - showMax,
		}"
	>
		<li
			v-for="(item, index) in showMax >= 0
				? items.slice(0, showMax)
				: items"
			:key="item.key"
			class="
				c-area-references__item
				inline-flex
				items-center
				px-4xs/h
				py-7
				min-h-32
				border
				rounded-25
				text-body-xs
				print:hidden
			"
			:class="{
				'border-theme border-opacity-20 text-theme': !invert,
				'border-white border-opacity-30 text-white': invert,
				'c-area-references__item--add-dots':
					showMax >= 0 &&
					items.length > showMax &&
					index === showMax - 1,
			}"
		>
			<AreaIcon
				v-if="item.iconUrl"
				class="w-18 h-18 mr-8"
				:invert="invert"
				:src="item.iconUrl"
			/>
			<span v-text="item.name"></span>
		</li>

		<!-- Just a simpler rendering when printing -->
		<span
			class="hidden print:inline-block text-body-xs"
			v-text="printText"
		></span>

		<slot></slot>
	</ul>
</template>

<script>
import AreaIcon from '~/components/main/AreaIcon';

export default {
	name: 'AreaReferences',

	components: {
		AreaIcon,
	},

	props: {
		invert: {
			type: Boolean,
			default: false,
		},
		items: {
			type: Array,
			default: () => [],
		},
		showMax: {
			type: Number,
			default: -1,
		},
	},

	computed: {
		printText() {
			return this.items.map((item) => item.name).join(', ');
		},
	},
};
</script>

<style lang="postcss">
.c-area-references {
	@apply flex flex-wrap gap-8;
}

/* Should we want to show a +N counter for the remaining items, this is the way to do it */
/* .c-area-references__item {
	@apply relative;
}
.c-area-references__item--add-dots {
	margin-right: 2em;
}
.c-area-references__item--add-dots::after {
	@apply absolute inline-block text-label-sm;
	counter-reset: more var(--more);
	content: '+'counter(more);
	left: calc(100% + 0.5em);
} */
</style>
