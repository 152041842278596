<!--
	Last modified: 2024/02/02 09:23:26
-->
<template>
	<div
		v-if="
			$scopedSlots.default || Object.entries($attrs).length || linkToToc
		"
		class="c-long-read-target"
	>
		<LongReadTargetTocLink
			v-if="linkToToc && !disabled"
			:base-id="containerId"
			:config="linkToToc"
		/>

		<slot></slot>
	</div>
</template>

<script>
import * as LongRead from './LongReadController';
import LongReadTargetTocLink from './LongReadTargetTocLink';

export default {
	name: 'LongReadTarget',

	components: { LongReadTargetTocLink },

	props: {
		containerId: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		linkToToc: {
			type: [Object, String, Boolean],
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},

		// Don't use this unless you understand what you are doing
		forcedOrder: {
			type: Array,
			default: () => [],
		},

		// If you need to pass on extra data the toc should know
		metaData: {
			type: Object,
			default: () => ({}),
		},
	},

	watch: {
		disabled(value) {
			this[value ? 'removeTarget' : 'addTarget']();
		},
		containerId(_, id) {
			this.removeTarget(id);
			this.addTarget();
		},
		metaData() {
			if (LongRead.data._targets[this.containerId]) {
				this.$set(
					LongRead.data._targets[this.containerId],
					'metaData',
					this.metaData || {}
				);
			}
		},
	},

	mounted() {
		!this.disabled && this.addTarget();
	},

	beforeDestroy() {
		this.removeTarget();
	},

	methods: {
		addTarget() {
			const order = [];

			if (this.forcedOrder?.length) {
				order.push(...this.forcedOrder);
			} else {
				let prevNode = this;
				while (prevNode.$parent) {
					const { children } = prevNode.$parent._vnode;

					!children?.length && order.push(0);
					children?.length &&
						children?.forEach((child, index) => {
							child.componentInstance?._uid === prevNode._uid &&
								order.push(index);
						});

					prevNode = prevNode.$parent;
				}
				order.reverse();
			}

			this.$set(LongRead.data?._targets, this.containerId, {
				id: this.containerId,
				tocItemId: `${this.containerId}__toc-item`,
				title: this.title,
				visibility: 0,
				inViewport: false,
				aboveViewport: false,
				metaData: this.metaData || {},
				order,
			});
		},

		removeTarget(id = this.containerId) {
			delete LongRead.data._targets[id];
			this.$set(LongRead.data, '_targets', { ...LongRead.data._targets }); // We need this for it to be reactive
		},
	},
};
</script>
